<template>
  <div>
    <breadcrumbs title="clients"/>

    <!-- CONTENT -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div :key="index" class="col-sm-3 col-md-3" v-for="(item,index) in data">
            <div class="box-icon-3 box-full-width">
              <a :href="item.url" target="_blank">
                <img :src="item.image_path != null ? item.image_path : `https://via.placeholder.com/600x400`"
                     @error="$event.target.src='https://via.placeholder.com/600x400'"
                     class="img-responsive"
                >
              </a>
            </div>
            <div class="spacer-30"></div>
          </div>
        </div>

        <div class="row page-button">
          <div class="col-sm-12 col-md-12 px-0">
            <ul class="pagination mx-0">
              <li :key="page" @click="pageClick(page)" v-bind:class="{ active: page == meta.current_page }"
                  v-for="page in meta.last_page"><a>{{ page }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Clients",
  components: {
    breadcrumbs: () => import('@/components/layout/Breadcrumb'),
  },
  data() {
    return {
      isLoading: true,
      data: null,
      links: null,
      meta: null,
      page: 1,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData(this.page)
    },
    getData(page) {
      this.isLoading = true;
      this.$axios.get(`clients`, {params: {page: page}}).then(res => {
        res.data.data;
        const response = res.data;
        this.data = response.data;
        this.links = response.links;
        this.meta = response.meta;
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
        this.scrollToTop();
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    pageClick(page) {
      this.getData(page)
    }
  },
}
</script>

<style scoped>
.image-client {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
</style>